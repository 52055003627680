<template>
  <v-card elevation="4" max-width="996" width="100%" rounded="lg" class="login" color="white">
    <div class="d-flex">
      <v-card
        class="card mx-md-0 mx-auto"
        :class="$vuetify.rtl ? 'rounded-r-lg rounded-l-0' : 'rounded-l-lg rounded-r-0'"
        max-width="546"
        :width="'100%'"
        flat
      >
        <v-img class="mb-6" heigth="40" max-width="184" src="@/assets/img/logo-long.svg"></v-img>
        <div class="f30 font-weight-bold mb-2">{{ $t('login.title') }}</div>
        <div class="gray--text mb-5">{{ $t('login.desc') }}</div>
        <v-btn class="mb-6" @click="signIn" depressed block height="56" outlined color="black">
          <v-img max-width="24" height="24" contain src="@/assets/icon/google.svg"></v-img>
          <div class="ms-4">{{ $t('btn.google') }}</div>
        </v-btn>
        <div class="d-flex align-center my-5">
          <v-divider></v-divider>
          <div class="mx-5 text-body-2 gray--text">{{ $t('login.orPhone') }}</div>
          <v-divider></v-divider>
        </div>
        <v-form @submit.prevent="login" lazy-validation>
          <div class="font-weight-medium">{{ $t('input.phoneLabel') }}</div>
          <v-text-field
            class="field64"
            v-model="phone"
            :error-messages="phoneErrors"
            :placeholder="$t('input.phonePla')"
            outlined
            required
            color="primary"
            height="64"
            maxLength="15"
          >
            <template v-slot:prepend-inner>
              <div class="d-flex align-top mt-1 link" @click="modal = true" :style="`width: ${$vuetify.rtl ? '104px' : '96px'}`">
                <img v-if="!$vuetify.rtl" :src="flagImg(countryCode)" />
                <v-card flat tiel width="15" height="20">
                  <v-icon style="font-size: 16px">{{ modal ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-card>
                <div class="ms-1">
                  <span v-if="!$vuetify.rtl">+</span>
                  {{ flagList.find((item) => item.ct == countryCode).cl }}
                  <span v-if="$vuetify.rtl">+</span>
                </div>
                <img v-if="$vuetify.rtl" class="ms-2" :src="flagImg(countryCode)" />
              </div>
            </template>
          </v-text-field>
          <div class="mb-6">
            <v-btn :disabled="phone.length < 9" depressed type="submit" block height="64" class="primary">{{ $t('btn.login') }}</v-btn>
          </div>
        </v-form>
        <div class="text-center text-body-2 gray--text">
          {{ $t('login.dontAccount') }} <router-link to="/sign-up" class="primary--text">{{ $t('btn.singUp') }}</router-link>
        </div>
      </v-card>
      <v-card class="d-md-block d-none" :class="$vuetify.rtl ? 'rounded-r-0 rounded-l-lg' : 'rounded-l-0 rounded-r-lg'" flat>
        <v-img
          class="d-block rounded-l-0 rounded-r-lg"
          :style="$vuetify.rtl ? 'transform: scale(-1, 1)' : ''"
          max-height="611"
          cover
          src="@/assets/img/login.svg"
        ></v-img>
      </v-card>
    </div>
    <v-dialog max-width="340" v-model="modal">
      <v-card flat class="pa-3" height="390">
        <v-text-field
          @keyup="searchCountry"
          class="field64 mb-2"
          v-model="country"
          hide-details
          outlined
          required
          color="primary"
          height="64"
        ></v-text-field>
        <v-card tile flat height="300" class="view-without-scroll">
          <div v-for="item in requestsFlag" :key="item.ct" class="my-4">
            <div class="d-flex link" @click="(countryCode = item.ct), (modal = false)">
              <img :src="flagImg(item.ct)" />
              <div class="clip">
                <span class="ms-2">{{ item[lang] }}</span>
                <span class="ms-2">(<span v-if="!$vuetify.rtl">+</span>{{ item.cl }}<span v-if="$vuetify.rtl">+</span>)</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import json from '@/codes.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      phone: '',
      flagList: json,
      country: '',
      requestsFlag: json,
      countryCode: 'sa',
      error: [],
    };
  },
  mounted() {
    const _onInit = (auth2) => {
      console.log('init OK', auth2);
    };
    const _onError = (err) => {
      console.log('error', err);
    };
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: process.env.VUE_APP_GOOGLE,
          scope: 'email profile openid',
          fetch_basic_profile: false,
        })
        .then(_onInit, _onError);
    });
  },
  methods: {
    searchCountry() {
      const value = this.country.toString().toLowerCase();
      this.requestsFlag = this.flagList.filter((i) => {
        return Object.keys(i).some((k) => {
          return i[k].toString().toLowerCase().indexOf(value) !== -1;
        });
      });
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    signIn() {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signIn().then((googleUser) => {
        const access_token = googleUser.getAuthResponse().access_token;
        this.$store.dispatch('social', { token: access_token, provider: 'google' }).then((res) => {
          sessionStorage.setItem('jwt_token', res.data.jwt_token);
          this.$store.dispatch('getProfile').finally(() => {
            if (this.profile.is_profile_completed) {
              this.$router.push('/');
              // } else if (this.profile.birthday == null || !this.profile.first_name || !this.profile.last_name) {
              //   this.$router.push('/info');
              // } else if (this.profile.location == null) {
              //   this.$router.push('/question/1');
              // } else if (!this.profile.preferred_market_sectors.length) {
              //   this.$router.push('/question/2');
              // } else if (this.profile.monthly_income_from == null || this.profile.monthly_income_to == null) {
              //   this.$router.push('/question/3');
              // } else if (!this.profile.income_sources.length) {
              //   this.$router.push('/question/4');
              // } else if (this.profile.invest_other_assets == null) {
              //   this.$router.push('/question/5');
            } else {
              this.$router.push('/info');
            }
          });
        });
      });
    },
    signOut() {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        console.log('User signed out.');
      });
    },
    async login() {
      this.error = [];
      await this.$store
        .dispatch('signIn', { country_code: this.countryCode.toUpperCase(), phone: this.phone })
        .then((res) => {
          sessionStorage.setItem('sid', res.data.sid);
          this.$router.push('/otp/login');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  watch: {
    modal() {
      if (!this.modal) {
        this.requestsFlag = this.flagList;
        this.country = '';
      }
    },
  },
  computed: {
    lang() {
      return this.$store.getters.langValue.key;
    },
    profile() {
      return this.$store.getters.profile;
    },
    phoneErrors() {
      const errors = [];
      this.error.find((item) => item == 'phone__required') && errors.push(this.$t('inputError.phoneRequired'));
      this.error.find((item) => item == 'phone__invalid') && errors.push(this.$t('inputError.phoneInvalid'));
      this.error.find((item) => item == 'phone__exists') && errors.push(this.$t('inputError.phoneExists'));
      this.error.find((item) => item == 'country__not_allowed') && errors.push(this.$t('inputError.countryAllowed'));
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push(this.$t('inputError.limitExceeded'));
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .login {
  .card {
    padding: 48px 53px 48px 80px;
  }
  @media screen and (max-width: 959px) {
    .card {
      padding: 36px 28px;
    }
  }
  @media screen and (max-width: 599px) {
    .card {
      padding: 36px 16px 120px;
    }
    border-radius: 0 !important;
    box-shadow: none !important;
    min-height: 100vh;
  }
}
</style>
